$(function () {
    $(document).on('click', '.block-categories .navbar-toggler .ic-chevron-down', function () {
        var t = $(this);
        t.parent().parent().find('.category-sub-menu').slideDown();
        t.parent().parent().find('.ic-chevron-down').hide();
        t.parent().parent().find('.ic-chevron-up').css('display', 'block');
    }).on('click', '.block-categories .navbar-toggler .ic-chevron-up', function () {
        var t = $(this);
        t.parent().parent().find('.category-sub-menu').slideUp();
        t.parent().parent().find('.ic-chevron-down').css('display', 'block');
        t.parent().parent().find('.ic-chevron-up').hide();
    }).on('click', '.js-search-filters-clear-all', function () {
        var t = $(this).attr('data-search-url');
        window.location.href = t;
    });
});
